import React from 'react'
import Layout from '../components/_App/layout'
import Seo from '../components/_App/seo'
import Navbar from '../components/_App/Navbar'
import Footer from '../components/_App/Footer'
import PageBanner from '../components/Common/PageBanner'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton
} from 'react-accessible-accordion'

const FAQ = () => (
  <Layout>
    <Seo title='FAQ' />

    <Navbar />

    <PageBanner pageTitle='Frequently Asked Questions' />

    <div className='faq-area ptb-80'>
      <div className='container'>
        <div className='faq-accordion'>
          <Accordion allowZeroExpanded preExpanded={['a']}>
            <AccordionItem uuid='a'>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span>How can I use the provided JupyterLab service?</span>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Click on "Home" and then (on the landing page) on "Apply Now"
                  button in the "Sign Up for using JupyterLab". This should open
                  your default email client with a stub email to the Coordinator.
                  Provide there your name, affiliation, and explain briefly, why
                  do you need to use the GeoML JupyterLab service.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem uuid='b'>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span>How do I start a (demo) ML web app at GeoML.ai?</span>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  In the landing page, simply click on any button named with "Start...".
                  The app launches in Voila and will ask for further inputs, if needed.
                  When finished, do not forget to close the app properly in order to
                  release the computing resources for other users.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem uuid='c'>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <span>
                    Can I collaborate with someone else on the same JupyterLab instance?
                  </span>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  This functionality is currently under construction.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
{/* Add more AccordionItems here */}
          </Accordion>
        </div>

        {/* <div className='faq-contact'>
          <h3>Ask Your Question</h3>
          <form>
            <div className='row'>
              <div className='col-lg-6 col-md-6'>
                <div className='form-group'>
                  <input
                    type='text'
                    placeholder='Name'
                    className='form-control'
                  />
                </div>
              </div>

              <div className='col-lg-6 col-md-6'>
                <div className='form-group'>
                  <input
                    type='email'
                    placeholder='Email'
                    className='form-control'
                  />
                </div>
              </div>

              <div className='col-lg-12 col-md-12'>
                <div className='form-group'>
                  <input
                    type='text'
                    placeholder='Subject'
                    className='form-control'
                  />
                </div>
              </div>

              <div className='col-lg-12 col-md-12'>
                <div className='form-group'>
                  <textarea
                    name='message'
                    cols='30'
                    rows='6'
                    placeholder='Message'
                    className='form-control'
                  ></textarea>
                </div>
              </div>

              <div className='col-lg-12 col-md-12'>
                <button className='btn btn-primary' type='submit'>
                  Submit Now!
                </button>
              </div>
            </div>
          </form>
        </div> */}
      </div>
    </div>

    <Footer />
  </Layout>
)

export default FAQ
